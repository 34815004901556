export const projects = [



{
    title: "Wastefree",
    description:
        "An app that tracks pantry items via receipt scanning, alerts users of expiration dates, and suggests recipes—helping households reduce food waste, save money, and make smarter consumption decisions.",
    logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
    linkText: "View Project",
    link: "https://github.com/jshah0428/Wastefree",
},

{
    title: "CU Run",
    description:
        "An interactive race-tracking system using OpenCV, React, WebSockets, and Next.js, processing real-time camera feeds to detect jersey colors and display live race progress with a dynamic scoreboard across the Watt lawn.",
    logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
    linkText: "View Project",
    link: "https://github.com/DavidBootle/WattScreenRacer",
},

{
    title: "LiGate",
    description:
        "A webapp designed to make research opportunities more accessible to undergradutes.",
    logo: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
    linkText: "View Project",
    link: "https://github.com",
},

{
    title: "Bias.ly",
    description:
        "Website created to detect the general sentiment in news articles about a specific keyword, utilizing sentinemnt analysis.",
    logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
    linkText: "View Project",
    link: "https://github.com/KrishnanS2006/Bias.ly",
},

{
    title: "Dispo.ly",
    description:
        "Custom trained food item compostability classification webapp utilizing TensorFlow and Bootstrap.",
    logo: "https://www.cdnlogo.com/logos/t/82/tensorflow.svg",
    linkText: "View Project",
    link: "https://github.com/Avemur/Dispo.ly/tree/main",
},

{
    title: "Napoleonic Wars Game",
    description:
        "Lead development for a game on the Roblox Platform, amassing over 500,000 plays, which aimed to replicate the Napoleonic Wars reaching 5-6 thousand active players on weekends.",
    logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/lua/lua.png",
    linkText: "View Project",
    link: "https://www.youtube.com/watch?v=RK2mk6L7QWA",
},

{
    title: "AI Tycoon",
    description:
        "Created a game designed to implement artificial development concepts into a game environment in order to introduce kids to the AI development process.",
    logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/lua/lua.png",
    linkText: "View Project",
    link: "https://www.roblox.com/games/13693296115/AI-Tycoon-Pre-Alpha",
},
]