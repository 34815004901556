import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/v2/D4E0BAQHCrkwG2qE6gw/company-logo_100_100/company-logo_100_100/0/1682352940783/clemson_university_logo?e=1735776000&v=beta&t=y-BCnmRja1Zqvp1C3narVA9h5p9eaM9rC9H6WoVpm5o"
								alt="clemson"
								className="work-image"
							/>
							<div className="work-title">Clemson University</div>
							<div className="work-subtitle">
								Teaching Assistant
							</div>
							<div className="work-duration">2024 - Present</div>
						</div>

						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/v2/D4E0BAQHCrkwG2qE6gw/company-logo_100_100/company-logo_100_100/0/1682352940783/clemson_university_logo?e=1735776000&v=beta&t=y-BCnmRja1Zqvp1C3narVA9h5p9eaM9rC9H6WoVpm5o"
								alt="clemson"
								className="work-image"
							/>
							<div className="work-title">Clemson University</div>
							<div className="work-subtitle">
								Paid Undergraduate Researcher
							</div>
							<div className="work-duration">2024 - Present</div>
						</div>
						<div className="work">
							<img
								src="./forgeIcon.png"
								alt="clemson forge"
								className="work-image"
							/>
							<div className="work-title">Clemson Forge</div>
							<div className="work-subtitle">
								Program Founder & Director
							</div>
							<div className="work-duration">2024 - Present</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
